<template>
    <v-row no-gutters class="d-block mt-3">
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-card>
                <v-card-text>
                    <v-form ref="form" v-model="valid" enctype="multipart/form-data">
                        <v-row>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <v-text-field
                                    v-model="unidadOperativaModel.institucion"
                                    :label="unidadOperativaForm.institucion.label"
                                    :placeholder="unidadOperativaForm.institucion.placeholder"
                                    :rules="unidadOperativaForm.institucion.rules"
                                    required
                                    prepend-icon="location_city"
                                />
                                <v-text-field
                                    v-model="unidadOperativaModel.unidad_operativa"
                                    :label="unidadOperativaForm.unidad_operativa.label"
                                    :placeholder="unidadOperativaForm.unidad_operativa.placeholder"
                                    :rules="unidadOperativaForm.unidad_operativa.rules"
                                    required
                                    prepend-icon="apartment"
                                />
                                <v-text-field
                                    v-model="unidadOperativaModel.modo_formacion"
                                    :label="unidadOperativaForm.modo_formacion.label"
                                    :placeholder="unidadOperativaForm.modo_formacion.placeholder"
                                    required
                                    prepend-icon="description"
                                    :rules="unidadOperativaForm.modo_formacion.rules"
                                />
                                <v-text-field
                                    v-model="unidadOperativaModel.lugar"
                                    :label="unidadOperativaForm.lugar.label"
                                    :placeholder="unidadOperativaForm.lugar.placeholder"
                                    required
                                    prepend-icon="description"
                                    :rules="unidadOperativaForm.lugar.rules"
                                />
                                <v-text-field
                                    type="number"
                                    v-model="unidadOperativaModel.nota_minima_aprobar"
                                    :label="unidadOperativaForm.nota_minima_aprobar.label"
                                    :placeholder="unidadOperativaForm.nota_minima_aprobar.placeholder"
                                    :rules="unidadOperativaForm.nota_minima_aprobar.rules"
                                    required
                                    prepend-icon="pin"
                                />
                                <!--
                                <v-row>
                                    <v-col cols="6">
                                        < !-- Que solo sea visible desde Editar-- >
                                        <v-select
                                            v-model="unidadOperativaModel.cat_tamanyo_id"
                                            :items="catTamanyos"
                                            item-text="nombre"
                                            item-value="cat_tamanyo_id"
                                            :label="unidadOperativaForm.cat_tamanyo_id.label"
                                            :placeholder="unidadOperativaForm.cat_tamanyo_id.placeholder"
                                            :rules="unidadOperativaForm.cat_tamanyo_id.rules"
                                            required
                                            prepend-icon="checklist"
                                        />
                                    </v-col>
                                    <v-col cols="6">
                                        <v-select
                                            v-model="unidadOperativaModel.cat_orientacion_id"
                                            :items="catOrientaciones"
                                            item-text="nombre"
                                            item-value="cat_orientacion_id"
                                            :label="unidadOperativaForm.cat_orientacion_id.label"
                                            :placeholder="unidadOperativaForm.cat_orientacion_id.placeholder"
                                            :rules="unidadOperativaForm.cat_orientacion_id.rules"
                                            required
                                            prepend-icon="checklist"
                                        />
                                    </v-col>
                                </v-row>
                                -->
                            </v-col>
                            <v-col cols="12" sm="6" md="6" lg="6" xl="6">
                                <!--
                                <v-file-input
                                    outlined
                                    truncate-length="50"
                                    v-model="unidadOperativaModel.plantilla"
                                    :label="unidadOperativaForm.plantilla.label"
                                    :placeholder="unidadOperativaForm.plantilla.placeholder"
                                    :rules="unidadOperativaForm.plantilla.rules"
                                    accept="application/pdf"
                                    prepend-icon="description"
                                    persistent-hint
                                    @change="plantillaChange"
                                    height="90"
                                >
                                    <template v-slot:append>
                                        <iframe class="mt-0" v-if="plantillaPreview"
                                                :src="plantillaPreview"
                                                width="150"
                                                height="90">
                                        </iframe>
                                    </template>
                                </v-file-input>
                                <v-file-input
                                    outlined
                                    truncate-length="50"
                                    v-model='unidadOperativaModel.firma_director_ejecutivo'
                                    :label="unidadOperativaForm.firma_director_ejecutivo.label"
                                    :placeholder="unidadOperativaForm.firma_director_ejecutivo.placeholder"
                                    :rules="unidadOperativaForm.firma_director_ejecutivo.rules"
                                    accept="image/png"
                                    prepend-icon="description"
                                    @change="firmaDirectorEjecutivoChange"
                                    height="90"
                                    persistent-hint
                                >
                                    <template v-slot:append>
                                        <img
                                            v-if="firmaDirectorEjectivoPreview"
                                            :src="firmaDirectorEjectivoPreview"
                                            style="max-height: 90px; object-fit: cover"
                                        >
                                    </template>
                                </v-file-input>

                                <v-file-input
                                    outlined
                                    truncate-length="50"
                                    v-model='unidadOperativaModel.firma_jefe_departamento'
                                    :label="unidadOperativaForm.firma_jefe_departamento.label"
                                    :placeholder="unidadOperativaForm.firma_jefe_departamento.placeholder"
                                    :rules="unidadOperativaForm.firma_jefe_departamento.rules"
                                    accept="image/png"
                                    prepend-icon="description"
                                    @change="firmaJefeDepartamentoChange"
                                    height="90"
                                >
                                    <template v-slot:append>
                                        <img
                                            v-if="firmaJefeDepartamentoPreview"
                                            :src="firmaJefeDepartamentoPreview"
                                            style="max-height: 90px; object-fit: cover"
                                        >
                                    </template>
                                </v-file-input>
                                -->

                                <!-- Ahora es una lista de unidades operativas ajenas -->
                                <v-select
                                    v-model="unidadOperativaModel.unidad_operativa_padre_id"
                                    :items="unidadesOperativasAjenas"
                                    item-text="unidad_operativa"
                                    item-value="unidad_operativa_id"
                                    :label="unidadOperativaForm.unidad_operativa_padre_id.label"
                                    :placeholder="unidadOperativaForm.unidad_operativa_padre_id.placeholder"
                                    :rules="unidadOperativaForm.unidad_operativa_padre_id.rules"
                                    required
                                    prepend-icon="checklist"
                                />

                                <!-- Ahora es una lista de plantillas existentes -->
                                <v-select
                                    v-model="unidadOperativaModel.plantilla_certificado_id"
                                    :items="plantillas"
                                    item-text="descripcion"
                                    item-value="plantilla_certificado_id"
                                    :label="unidadOperativaForm.plantilla_certificado_id.label"
                                    :placeholder="unidadOperativaForm.plantilla_certificado_id.placeholder"
                                    :rules="unidadOperativaForm.plantilla_certificado_id.rules"
                                    required
                                    prepend-icon="checklist"
                                />

                                <!-- Ahora es una lista de firmas existentes -->
                                <v-select
                                    v-model="unidadOperativaModel.persona_id_director_ejecutivo"
                                    :items="firmas"
                                    item-text="nombre_completo"
                                    item-value="persona_id"
                                    :label="unidadOperativaForm.persona_id_director_ejecutivo.label"
                                    :placeholder="unidadOperativaForm.persona_id_director_ejecutivo.placeholder"
                                    :rules="unidadOperativaForm.persona_id_director_ejecutivo.rules"
                                    required
                                    prepend-icon="checklist"
                                />

                                <!-- Ahora es una lista de firmas existentes -->
                                <v-select
                                    v-model="unidadOperativaModel.persona_id_jefe_departamento"
                                    :items="firmas"
                                    item-text="nombre_completo"
                                    item-value="persona_id"
                                    :label="unidadOperativaForm.persona_id_jefe_departamento.label"
                                    :placeholder="unidadOperativaForm.persona_id_jefe_departamento.placeholder"
                                    :rules="unidadOperativaForm.persona_id_jefe_departamento.rules"
                                    required
                                    prepend-icon="checklist"
                                />


                                <v-row>
                                    <v-col cols="6">
                                        <v-switch v-show="$route.query.token"
                                                v-model="unidadOperativaModel.activo"
                                                :label="unidadOperativaForm.activo.label"
                                                color="success"
                                                class="font-weight-bold"
                                                inset
                                                hide-details
                                        ></v-switch>
                                    </v-col>
                                    <v-col cols="6"></v-col>
                                </v-row>
                            </v-col>

                        </v-row>

                    </v-form>
                </v-card-text>
                <v-divider class="mt-1"></v-divider>
                <v-card-actions class="justify-center py-3">
                    <v-btn outlined small @click="regresar()">
                        <v-icon>arrow_back</v-icon>
                        Regresar
                    </v-btn>

                    <v-btn outlined small color="warning" @click="reset()">
                        <v-icon>restart_alt</v-icon>
                        Limpiar
                    </v-btn>

                    <v-btn color="primary" small :loading="loading" :disabled="loading" @click="unidadOperativaSave()">
                        <v-icon>save</v-icon>
                        Guardar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </v-row>

</template>

<script>

export default {
    name: "AdministracionunidadOperativaFormulario",
    props: {
        // Si es requerido falso, que sea Default Vacio
        getParams: {
            type: Object,
            required: false,
        }
    },
    data() {
        return {
            valid: true,
            loading: false,
            //catTamanyos: [],
            plantillas: [],
            firmas: [],
            unidadesOperativasAjenas: [],
            //catOrientaciones: [],
            unidadOperativaModel: {
                unidad_operativa_id: null,
                unidad_operativa_padre_id: null,
                institucion: null,
                unidad_operativa: null,
                nota_minima_aprobar: null,
                //firma_director_ejecutivo: [],
                //firma_jefe_departamento: [],
                persona_id_director_ejecutivo: null,
                persona_id_jefe_departamento: null,
                lugar: "Tegucigalpa",
                modo_formacion: null,
                plantilla_certificado_id: null,
                //cat_tamanyo_id: 3,
                //cat_orientacion_id: 4,
                activo: true,
            },
            firmaDirectorEjectivoPreview: [],
            firmaJefeDepartamentoPreview: [],
            //plantillaPreview: [],
            unidadOperativaForm: {
                institucion: {
                    label: 'Institución',
                    placeholder: 'Institución',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                unidad_operativa: {
                    label: 'Unidad Operativa',
                    placeholder: 'Unidad Operativa',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                nota_minima_aprobar: {
                    label: 'Nota mínima para Aprobar',
                    placeholder: 'Nota mínima para Aprobar',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                modo_formacion: {
                    label: 'Modo de formación',
                    placeholder: 'Modo de formación',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                lugar: {
                    label: 'Lugar de Certificación',
                    placeholder: 'Lugar de Certificación',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                unidad_operativa_padre_id: {
                    label: 'Unidad Operativa Superior',
                    placeholder: 'Seleccione',
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                plantilla_certificado_id: {
                    label: 'Plantilla de Certificado',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                persona_id_director_ejecutivo: {
                    label: 'Director Ejecutivo',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                persona_id_jefe_departamento: {
                    label: 'Jefe Departamento',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.pdf)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                /*
                firma_director_ejecutivo: {
                    label: 'Firma Director Ejecutivo',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.png)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                firma_jefe_departamento: {
                    label: 'Firma Jefe Departamento',
                    placeholder: 'Seleccione',
                    //hint: "Formato (.png)",
                    rules: [
                        (v) => !!v || 'Campo requerido',
                    ],
                },
                */
                /*
                cat_tamanyo_id: {
                    label: 'Tamaño',
                    placeholder: 'Seleccione',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                cat_orientacion_id: {
                    label: 'Orientación',
                    placeholder: 'Seleccione',
                    rules: [(v) => !!v || 'Campo requerido'],
                },
                */
                activo: {
                    label: 'Activo',
                },
            },
        }
    },
    computed: {},
    methods: {
        regresar() {
            this.$router.go(-1)
        },
        reset() {
            // Validar si estamos editando
            if (Number(this.unidadOperativaModel.unidad_operativa_id)) {
                this.getUnidadOperativaEdit();
            } else {
                this.$refs.form.reset();
            }
        },
        goToEdit() {
            let token = window.btoa(this.unidadOperativaModel.unidad_operativa_id);
            this.$router.push({name: 'AdministracionUnidadesOperativasEditar', query: {token}});
        },
        getUnidadOperativaCreate() {
            this.loading = true;

            // Get data;
            window.axios.get('/unidades_operativas/create', {}).then(response => {
                if (response.data.success) {
                    this.plantillas = response.data.plantillas;
                    this.firmas = response.data.firmas;
                    this.unidadesOperativasAjenas = response.data.unidadesOperativasAjenas;
                    this.getUnidadOperativaEdit();
                } else if (response.data.success === false) {
                    this.$toast.error(response.data.message);
                }
            })
                .catch(error => {
                    console.log(error)
                    this.$toast.error("No se pudo obtener los datos para continuar.");
                })
                .finally(() => {
                    // Do some
                    this.loading = false;
                })
        },
        getUnidadOperativaEdit() {
            // Validar operaciones
            if (Number(this.getParams.unidad_operativa_id)) {
                this.loading = true;
                this.unidadOperativaModel.unidad_operativa_id = this.getParams.unidad_operativa_id;
                let params = {unidad_operativa_id: this.unidadOperativaModel.unidad_operativa_id}
                window.axios.get('/unidades_operativas/edit', {params}).then(response => {
                    if (response.data.success) {
                        this.plantillas = response.data.plantillas;
                        this.firmas = response.data.firmas;
                        this.unidadesOperativasAjenas = response.data.unidadesOperativasAjenas;
                        if (response.data.data.length) {
                            this.unidadOperativaModel = response.data.data[0];
                            //this.plantillaPreview =`${this.baseURL}/${this.unidadOperativaModel.plantilla}`;
                            //this.firmaDirectorEjectivoPreview =`${this.baseURL}/${this.unidadOperativaModel.firma_director_ejecutivo}`;
                            //this.firmaJefeDepartamentoPreview = `${this.baseURL}/${this.unidadOperativaModel.firma_jefe_departamento}`;
                        } else {
                            this.$toast.error("No se encontraron los datos de la Unidad Operativa.");
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo obtener la información de la Unidad Operativa.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            }
        },
        unidadOperativaSave() {
            // Validar
            if (this.$refs.form.validate()) {
                this.loading = true;
                let url = (Number(this.unidadOperativaModel.unidad_operativa_id)) ? '/unidades_operativas/update' : '/unidades_operativas/store';
                let formData = new FormData;
                formData.append("unidad_operativa_padre_id", this.unidadOperativaModel.unidad_operativa_padre_id);
                formData.append("unidad_operativa_id", this.unidadOperativaModel.unidad_operativa_id);
                formData.append("institucion", this.unidadOperativaModel.institucion);
                formData.append("unidad_operativa", this.unidadOperativaModel.unidad_operativa);
                formData.append("nota_minima_aprobar", this.unidadOperativaModel.nota_minima_aprobar);
                formData.append("plantilla_certificado_id", this.unidadOperativaModel.plantilla_certificado_id);
                formData.append("persona_id_director_ejecutivo", this.unidadOperativaModel.persona_id_director_ejecutivo);
                formData.append("persona_id_jefe_departamento", this.unidadOperativaModel.persona_id_jefe_departamento);
                //formData.append("firma_director_ejecutivo", this.unidadOperativaModel.firma_director_ejecutivo);
                //formData.append("firma_jefe_departamento", this.unidadOperativaModel.firma_jefe_departamento);
                formData.append("lugar", this.unidadOperativaModel.lugar);
                formData.append("modo_formacion", this.unidadOperativaModel.modo_formacion);
                //formData.append("cat_tamanyo_id", this.unidadOperativaModel.cat_tamanyo_id);
                //formData.append("cat_orientacion_id", this.unidadOperativaModel.cat_orientacion_id);
                formData.append("activo", this.unidadOperativaModel.activo);
                window.axios.post(url, formData).then(response => {
                    if (response.data.success) {
                        this.$toast.success(response.data.message);
                        // Regresar, si es Editar
                        if (Number(this.unidadOperativaModel.unidad_operativa_id)) {
                            this.getUnidadOperativaEdit();
                        } else {
                            // MANDAR a la interfaz de EDITAR
                            this.unidadOperativaModel.unidad_operativa_id = response.data.data;
                            this.goToEdit();
                        }
                    } else if (response.data.success === false) {
                        this.$toast.error(response.data.message);
                    }
                })
                    .catch(error => {
                        console.log(error)
                        this.$toast.error("No se pudo guardar.");
                    })
                    .finally(() => {
                        // Do some
                        this.loading = false;
                    })
            } else {
                this.$toast.warning("Complete toda la información requerida.");
            }
        },
        /*
        plantillaChange(payload) {
            let file = payload;
            if (file) {
                this.plantillaPreview = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.plantillaPreview = null
            }
        },
        firmaDirectorEjecutivoChange(payload) {
            let file = payload;
            if (file) {
                this.firmaDirectorEjectivoPreview = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.firmaDirectorEjectivoPreview = null
            }
        },
        firmaJefeDepartamentoChange(payload) {
            let file = payload;
            if (file) {
                this.firmaJefeDepartamentoPreview = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.firmaJefeDepartamentoPreview = null
            }
        },
        */
    },
    created() {
        this.getUnidadOperativaCreate();
    }
}
</script>

<style lang="scss" scoped>
.image-preview {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 20px;
}

img.preview {
    width: 200px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
}

</style>
